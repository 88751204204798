const mixpanel = require('mixpanel-browser');
//const fullstory = require('@fullstory/browser');
const axios = require("axios");

function setFullStorySessionUrl(sessionUrl) {

    if (sessionUrl == null)
        return;

    axios.post('/Monitor/SetFullStorySessionUrl', sessionUrl)
        .then((response) => {
            if (response.data.Data) {
                console.log('VISION2: Full story url is set');
            }

        })
        .catch((error) => {
            console.error('VISION2: Unable to set full story session url', error);
        });

}

function configureHubspotChat() {


    if (window.HubSpotConversations) {
        //console.log('The api is ready already');
    } else {
        window.hsConversationsOnReady = [
            () => {
                console.log('Hubspot conversations ready');

            },
        ];
    }

}


function configureFullStory() {



    fullstory.init({
        orgId: 'HWW6F',
        readyCallback: function (sessionUrl) {
            console.log('Started session: ' + sessionUrl)
            setFullStorySessionUrl(sessionUrl);
        }
    });

    window.fullstory = fullstory;



}

function configureMixPanel() {
    mixpanel.init('202b44c4c51e344332cef60e01cc7e24', { debug: true });
}




function initialize() {
   // configureFullStory();
   // configureMixPanel();
}

function trackEvent(e, obj) {
    mixpanel.track(e, obj);
}

function loginEvent(user) {

    try {
        console.log('identifying user mix panel');
        if (!mixpanel)
            return;
        mixpanel.identify(user.Id);
        mixpanel.register({
            email: user.emailAddress,
            organizationname: user.organization,
            organizationid: user.organizationId,
            roles: user.roles
        });
    }
    catch {
        console.error("unable to identify user in mix panel");
    }

}

//called by layout in admin portal shared
function identify(user) {

    if (!user.disableIntercom) {

        // console.log('enabling intercom...', user.intercom_hash);
        const settings = {
            user_id: user.Id,
            name: user.name,
            email: user.emailAddress,
            created_at: user.created_at,
            user_hash: user.intercom_hash,
            app_id: user.intercomapp_id,
            company: user.company
        };




       // window.intercomSettings = settings;
        //console.log('user', user, settings);
        //configureIntercom(settings);
        configureHubspotChat();
    }

    if (!user.disableSupportChat && window.hsConversationsSettings) {
        window.hsConversationsSettings.identificationEmail = user.emailAddress;
        window.hsConversationsSettings.identificationToken = user.hubspot_token;
        window.hsConversationsSettings.loadImmediately = true;
        //window.HubSpotConversations.widget.load();
    }



    try {

        //fullstory.identify(user.Id, {
        //    displayName: user.name,
        //    email: user.emailAddres,
        //    organization: user.organization,
        //    organizationId: user.organizationId
        //});

    }
    catch {
        console.error("unable to identify user for full story")
    }

    // console.log('identifying user in full story');






}



window.appAnalytics = {
    Initialize: initialize,
    TrackEvent: trackEvent,
    LoginEvent: loginEvent,
    Identify: identify
}
